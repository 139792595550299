import React from 'react';
import {ChatWidget} from "@papercups-io/chat-widget";

const Chatbot = () => {
  return(
    <ChatWidget
      accountId="e19813e3-e7ac-421f-86b6-f390a34c1319"
      title="Welcome to Wanclouds"
      subtitle="Ask us anything in the chat window below 😊"
      primaryColor="#1e6beb"
      greeting=""
      newMessagePlaceholder="Start typing..."
      showAgentAvailability={false}
      agentAvailableText="We're online right now!"
      agentUnavailableText="We're away at the moment."
      requireEmailUpfront={true}
      iconVariant="filled"
      baseUrl="https://app.papercups.io"
    />
  );
}
export default Chatbot;
